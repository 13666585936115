import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const SupportPage = () => {
	return (
		<Layout>
			<Seo title="Support" />
			<h2>Support</h2>
			<div className="main--centerwrapper">
				<StaticImage
				src="../images/photos/pressRes/jesseinrocky.jpg"
				alt="Jesse Rivest press image"
				placeholder="blurred"
				layout="constrained"
				className="main--centered"
				imgClassName="img--bordered-small"
				height={333}
				/>
			</div>

			<h3 id="">How can you support me?</h3>
			<p className="card">
				<span className="card">
					The very best way to support me is to <a href="//bit.ly/email-list-JR">join my mailing list</a> or
					my <a href="//patreon.com/jesserivest/">Patreon</a> (payment optional).
					Following me on social media is <span className="italic">far</span> less effective, unfortunately.
					You'll want to ensure my emails don't go into your spam folder, too.
					I don't email too frequently, while Patreon is meant to be weekly or every other week.
				</span>
			</p>
			<p>
				Other excellent ways to support me are:
			</p>
			<ul>
				<li>
					share any music, video, website page or social post of mine that you like with your friends, family, or colleagues
					(can even be via social media!)
				</li>
				<li>
					money always helps 😎 (see <Link to="/pay/">Payments</Link>)
				</li>
				<li>
					send me an encouraging message or some positive feedback (see <Link to="/contact/">Contact</Link>)
				</li>
			</ul>

			<div id="share"></div>
			<div id="mailinglist"></div>
			<div id="connectme"></div>
			<div id="sparecash"></div>
			<div id="sparetime"></div>

		</Layout>
	);
}

export default SupportPage;
